/* src/components/SvgViewer.css */
.svg-viewer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* margin-left: 16px; */
    justify-content: center;
    align-items: center;
  }

  
  .svg-item {
      cursor: pointer;
      border: 1px solid #ddd;
      /* padding: 8px; */
      max-width: calc(20% - 16px); /* 5 items per row */
      box-sizing: border-box;
      background-color: white;
    }
    
    .svg-item img {
        width: 100%;
        height: auto;
    }
    
    
      .bg-adjuster {
          margin: 24px;
        }
        
        .svg-modal-overlay {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;
        }
        
        li {
            margin: 16px;
        }
        
        .svg-modal-content {
            color: black;
            background: white;
            padding: 10px;
            max-width: 90%;
            max-height: 90%;
            overflow: auto;
            border-radius: 8px;
            position: relative;
        }
        
        .svg-modal-close {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            color: #555;
            font-size: 20px;
        }
        
        .svg-modal-flex-container {
            display: flex;
            flex-wrap: wrap; /* Allow text wrapping */
        }
        
        .svg-modal-details-container {
            display: flex;
            flex-direction: column; /* Arrange children vertically */
            flex: 0 0 100%; /* Full width for mobile, adjust as needed for larger screens */
        }
        
        .svg-modal-image-container{
            background-color: rgb(255, 255, 255);
            padding: 10px;
            box-sizing: border-box;
            /* flex: 0 0 48%; Adjust width as needed */
        }
        
        .svg-modal-download-container,
        .svg-modal-code-container {
            padding: 10px;
            box-sizing: border-box;
            /* flex: 0 0 48%; Adjust width as needed */
        }
        
        .svg-modal-left-column,
        .svg-modal-right-column {
            flex: 0 0 48%; /* Adjust width as needed */
            padding: 10px;
            box-sizing: border-box;
  }
  
  .svg-modal-instructions-container {
      text-align: left;
    }
    
    h3 {
        text-align: center;
    }
    
    .svg-item-name {
        margin-top: 8px;
        text-align: center;
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(0, 0, 0);
    font-weight: bolder;
}
button {
    margin:8px;
}

strong {
    color: red;
}
/* Ensure text within the code container wraps and is left-aligned */
  .svg-modal-code-container pre {
      white-space: pre-wrap;
      text-align: left;
    }
    
        /* Adjust the number of items per row for smaller screens */
    @media screen and (max-width: 800px) {
        .svg-item {
          max-width: calc(25% - 16px); /* 2 items per row */
        }
      }
    @media screen and (max-width: 600px) {
        .svg-item {
          max-width: calc(50% - 16px); /* 2 items per row */
        }
      }
      
    /* Mobile-specific styles */
    @media only screen and (max-width: 600px) {
        .svg-modal-content {
          max-width: 90%;
        }
      
        .svg-modal-left-column,
        .svg-modal-right-column {
          flex: 1 1 100%; /* Full width on mobile */
          padding: 1px;
          box-sizing: border-box;
        }
      
        .svg-modal-code-container pre {
          max-width: 100%; /* Allow code to take full width */
          text-wrap: pretty;
        }
      }
    
    